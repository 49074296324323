import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import { Notyf } from "notyf";
import Pusher from "pusher-js";

export default class extends Controller {
  static values = {
    url: String,
    orderReference: String,
    paymentReference: String,
    paymentStatus: String,
    redirectUrl: String,
    qrPersisted: Boolean,
  };
  static targets = [
    "qrCode",
    "qrCodeContainer",
    "alertContainer",
    "loadingSpinner",
    "amount",
    "expiresAt",
    "nit",
  ];

  connect() {
    // Connect to the Pusher service in order to receive the relevant payment events.
    // We need to receive two events: one when the QR code is generated and other when
    // The payment status changes.
    this.pusher = new Pusher("f8e7b6ca2cdf042aa1f1", {
      cluster: "sa1",
    });

    this.channel = this.pusher.subscribe(
      `order-${this.orderReferenceValue}-payment`
    );

    this.channel.bind("qr-payment-created", (data) => {
      this._handleQrPaymentCreated(data);
    });
    this.channel.bind("status-change", (data) => {
      this._handlePaymentStatusChange(data);
    });

    // If we're loading the page and the QR code is already generated and persisted, we
    // need to check for the payment status with a long polling function in order to decide
    // what to do next
    if (this.qrPersistedValue) {
      this._checkPaymentStatus();
    }
  }

  _handleQrPaymentCreated(data) {
    this._populateFields(data);
    this._hideLoadingSpinner();
    this._checkPaymentStatus();
  }

  _handlePaymentStatusChange(payload) {
    if (payload.status === "approved") {
      this._handleSuccessfullPayment();
    }

    if (payload.status === "cancelled") {
      this._handleCancelledPayment();
    }

    if (payload.status === "revoked") {
      this._handleRevokedPayment();
    }
  }

  _checkPaymentStatus() {
    setInterval(() => {
      Rails.ajax({
        url: `/api/v1/orders/${this.orderReferenceValue}/check_payment_status`,
        type: "GET",
        success: (response) => {
          console.log(response);
          switch (response.paymentStatus) {
            case "PROCESSING":
              break;
            case "PROCESSED":
              this._handleSuccessfullPayment();
              this._sendPaymentStatusToServer(response.paymentStatus);
              break;
            case "CANCELLED":
              this._sendPaymentStatusToServer(response.paymentStatus);
              break;
            case "PENDING":
              this._sendPaymentStatusToServer(response.paymentStatus);
              break;
            case "REVOKED":
              this._sendPaymentStatusToServer(response.paymentStatus);
              break;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }, 6500);
  }

  _hideLoadingSpinner() {
    this.loadingSpinnerTarget.classList.add("hidden");
  }

  _populateFields(data) {
    this.nitTarget.classList.remove("hidden");

    this.qrCodeTarget.src = data.qr_code_image_url;
    this.qrCodeTarget.classList.remove("hidden");

    this.amountTarget.querySelector("span").innerHTML = data.amount;
    this.amountTarget.classList.remove("hidden");

    this.expiresAtTarget.querySelector("span").innerHTML = data.qr_expires_at;
    this.expiresAtTarget.classList.remove("hidden");
  }

  _sendPaymentStatusToServer(paymentStatus) {
    Rails.ajax({
      url: `/api/v1/orders/${this.orderReferenceValue}/update_payment_status`,
      type: "PUT",
      data: `payment_status=${paymentStatus}`,
      success: (response) => {},
      error: (error) => {
        let notyf = new Notyf({
          duration: 750,
          position: { x: "right", y: "top" },
          icon: { className: ["fal", "fa-check"] },
          tagName: "i",
          background: "blue",
        });

        notyf.error("Hubo un problema al procesar el pago");
      },
    });
  }

  _handleSuccessfullPayment() {
    window.location.href = this.redirectUrlValue;
    let notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
      icon: { className: ["fal", "fa-check"] },
      tagName: "i",
      background: "blue",
    });

    notyf.success("El pago fue exitoso. Gracias por tu compra!");
  }

  _handleCancelledPayment() {
    let notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
      icon: { className: ["fal", "fa-check"] },
      tagName: "i",
      background: "blue",
    });

    notyf.error("El pago fue cancelado");
  }

  _handleRevokedPayment() {
    let notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
      icon: { className: ["fal", "fa-check"] },
      tagName: "i",
      background: "blue",
    });

    notyf.error("El pago fue revocado");
  }
}
